export type QrReaderMediaErrorName =
  | 'NotAllowedError'
  | 'NotFoundError'
  | 'NotSupportedError'
  | 'NotReadableError'
  | 'OverconstrainedError'
  | 'StreamApiNotSupportedError'

export interface QrReaderMediaError extends Error {
  name: QrReaderMediaErrorName
}

export function getQrReaderMediaErrorMessage(errorName: string) {
  switch (errorName) {
    case 'NotAllowedError':
      return 'カメラへのアクセス許可が拒否されました。ブラウザの設定からカメラを許可してください。'
    case 'NotFoundError':
      return '適切なカメラデバイスが見つかりません'
    case 'NotSupportedError':
      return 'ページがHTTPS（またはlocalhost）で提供されていません。'
    case 'NotReadableError':
      return 'カメラが既に使用中の可能性があります。'
    case 'OverconstrainedError':
      return 'フロントカメラが要求されましたが、デバイスにフロントカメラがない可能性があります。'
    case 'StreamApiNotSupportedError':
      return 'ブラウザに必要な機能が不足しているようです。'
    default:
      return '不明なエラーが発生しました。'
  }
}
