<template>
  <WebCatalogNavigation v-if="true" />
</template>

<script lang="ts">
import WebCatalogNavigation from './navigations/WebCatalogNavigation.vue'

export default defineComponent({
  components: {
    WebCatalogNavigation,
  },
})
</script>
